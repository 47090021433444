import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Profile from './Profile';
import Grid from '@mui/material/Grid';

function PeopleSection({sectionName, sectionData, showImg, showTitle}) {
    return (
        <Box sx={{
            mt: 0,
            // borderTop: 'solid',
            borderColor: 'rgb(215, 211, 211)',
            borderTopWidth: '1px',
            mb: 5,
        }}>
            <Typography variant={'h4'} textAlign='center' sx={{mb: 3, fontWeight: '500', paddingTop: '60px'}}>
                {sectionName}
            </Typography>
            <Grid container rowSpacing={2} sx={{display: 'flex', justifyContent: 'center'}}>
                {sectionData.map((data, i) => (
                    <Grid item key={i} xs={6} sm={4} md={3}>
                        <Profile
                            showImg={showImg}
                            showTitle={showTitle}
                            img={data.imgName}
                            name={data.name}
                            title={data.title}
                            link={data.link}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default PeopleSection;
