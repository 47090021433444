import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {peopleData} from '../data/peopledata';
import PeopleSection from './PeopleSection';

function People() {
    return (
        <Container maxWidth='lg'>
            <Box
                sx={{
                    py: 2,
                    mb: 2,
                    // borderBottomWidth: '1px',
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                <Typography variant={'h3'} textAlign={'start'}>
                    People
                </Typography>
            </Box>
            <PeopleSection sectionName={'Faculty'}
                           sectionData={peopleData.faculty}
                           showImg={true}
                           sx={{paddingTop: '0px'}}
                           showTitle={true}/>
            <hr/>
            <PeopleSection
                sectionName={'Graduate Students'}
                sectionData={peopleData.grad}
                showImg={true}
                showTitle={true}
            />
            <hr/>
            <PeopleSection
                sectionName={'Interns'}
                sectionData={peopleData.interns}
                showImg={true}
                showTitle={false}
            />
            <hr/>
            <PeopleSection
                sectionName={'Associated Students and Researchers'}
                sectionData={peopleData.others}
                showImg={true}
                showTitle={true}
            />
            <hr/>
            <PeopleSection
                sectionName={'Lab Assistants'}
                sectionData={peopleData.assistant}
                showImg={true}
                showTitle={true}
            />
            <hr/>
            <PeopleSection
                sectionName={'Graduate Alumni'}
                sectionData={peopleData.grad_alumni}
                showImg={false}
                showTitle={true}/>
            <hr/>
            <PeopleSection
                sectionName={'Intern Alumni'}
                sectionData={peopleData.intern_alumni}
                showImg={false}
                showTitle={false}/>
        </Container>
    );
}

export default People;
