import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {conferenceLink, publications} from '../data/publicationData';
import Publication from './Publication';

function PublicationPage() {
    return (
        <Container maxWidth='lg'>
            <Box
                sx={{
                    py: 2,
                    mb: 2,
                    borderBottom: 'solid',
                    borderColor: 'rgb(215, 211, 211)',
                    borderBottomWidth: '1px',
                }}>
                <Typography variant={'h3'} textAlign='start'>
                    Publications
                </Typography>
            </Box>
            {publications.map((p, i) => {
                p.conferenceLink = conferenceLink[p.conference]
                return <Publication key={i} publication={p}/>
            })}
        </Container>
    );
}

export default PublicationPage;
