import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material';

function Profile({img, name, title, link, showImg, showTitle}) {
    const theme = useTheme();
    return (
        <Box sx={{paddingBottom: '20px'}}>
            {showImg && (
                <Box
                    sx={{
                        height: '150px',
                        width: '150px',
                        mb: 2,
                        borderRadius: '50%',
                        margin: 'auto',
                        paddingTop: '45px'
                        // boxShadow: '3px 6px 19px -4px rgba(0,0,0,0.45)',
                    }}>
                    <a href={link ? link : null} rel={"noreferrer"} target='_blank'>
                        {img && (
                            <img
                                src={require(`../assets/people/${img}`)}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    objectPosition: 'top',
                                    borderRadius: '50%',
                                }}
                                alt={`${img}`}
                            />
                        )}
                    </a>
                </Box>
            )}
            <a href={link ? link : null} style={{"textDecoration": "none"}} rel={"noreferrer"} target='_blank'>
                <Typography textAlign={'center'} variant={'h5'}
                            sx={{color: theme.palette.info.main, paddingTop: '10px'}}>
                    {name}
                </Typography>
            </a>
            {
                showTitle && (
                    <Typography textAlign={'center'} variant={'h6'}>
                        {title}
                    </Typography>
                )
            }
        </Box>
    );
}

export default Profile;
