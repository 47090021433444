import './App.css';
import Navbar from './components/Navbar';
import {Outlet} from 'react-router-dom';
import Footer from './components/Footer';
import Box from '@mui/material/Box';

function App() {
    return (
        <div className='App'>
            <Navbar/>
            <Box sx={{minHeight: '95vh'}}>
                <Outlet/>
            </Box>
            <Footer/>
        </div>
    );
}

export default App;
