import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material/styles';

function Research({research}) {
    const theme = useTheme();
    const {title} = research;
    return (
        <Box
            sx={{
                my: 3,
                mx: 2,
                pb: 3,
                textAlign: 'start',
                borderBottom: 'solid',
                borderColor: 'rgb(215, 211, 211)',
                borderBottomWidth: '1px',
            }}>
            <Typography
                variant={'h6'}
                sx={{color: theme.palette.secondary.main, display: 'inline-block'}}>
                {title}
            </Typography>
        </Box>
    );
}

export default Research;
