import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material/styles';

function isValidURL(string) {
    const res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g);
    return (res !== null)
}

function Publication({publication}) {
    const theme = useTheme();
    const {title, author, link, conference, conferenceLink, extraResources} = publication;
    return (
        <Box
            sx={{
                my: 3,
                mx: 2,
                pb: 3,
                textAlign: 'start',
                borderBottom: 'solid',
                borderColor: 'rgb(215, 211, 211)',
                borderBottomWidth: '1px',
            }}>
            <a
                href={isValidURL(link) ?
                    link : require(`../data/publications/${link}.pdf`)}
                rel={"noreferrer"}
                target={'_blank'}
                style={{textDecoration: 'none'}}>
                <Typography
                    variant={'h6'}
                    sx={{color: theme.palette.info.main, display: 'inline-block'}}
                    fontWeight={'bold'}>
                    {title}
                </Typography>
            </a>
            <Typography>{author}</Typography>
            <a href={conferenceLink} style={{textDecoration: 'none'}}>
                <Typography
                    variant={'h6'}
                    sx={{color: theme.palette.info.main, fontStyle: 'italic', display: 'inline-block'}}>
                    {conference}
                </Typography>
            </a>
            {extraResources?.map((e, i) => (
                <Box key={i}>
                    <Typography sx={{display: 'inline-block', my: 0.5}} variant={'h7'}>
                        {e.name}
                    </Typography>
                    {Object.keys(e.format).map((f, i) => (
                        <a
                            key={i}
                            href={
                                f === 'pptx' || f === 'pdf'
                                    ? require(`../data/publications/${e.format[f]}.${f}`)
                                    : e.format[f]
                            }
                            style={{textDecoration: 'none'}}>
                            <Typography
                                variant={'h7'}
                                sx={{color: theme.palette.info.main, display: 'inline-block', mx: 1}}>
                                {f}
                            </Typography>
                        </a>
                    ))}
                </Box>
            ))}
        </Box>
    );
}

export default Publication;
