import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Home from './components/Home';
import People from './components/PeoplePage';
import Publications from './components/PublicationPage';
import theme from './theme';
import {ThemeProvider} from '@mui/material';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import Contact from './components/ContactPage';
import Research from './components/ResearchPage'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ScrollToTop/>
            <ThemeProvider theme={theme}>
                <Routes>
                    <Route path='/' element={<App/>}>
                        <Route index element={<Home/>}/>
                        <Route path='people' element={<People/>}/>
                        <Route path='publications' element={<Publications/>}/>
                        <Route path='contact' element={<Contact/>}/>
                        <Route path='research' element={<Research/>}/>
                    </Route>
                </Routes>
            </ThemeProvider>
        </BrowserRouter>
    </React.StrictMode>
);
