import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useTheme} from '@mui/material';
import {Link} from 'react-router-dom';

function Thumbnail({image, text}) {
    const theme = useTheme();
    return (
        <Link to={text.toLowerCase()}>
            <Box sx={{position: 'relative', width: '100%', height: '100%', maxHeight: '20em'}}>
                <img
                    style={{
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        filter: 'brightness(0.75) blur(0.5px)',
                    }}
                    src={image}
                    alt={text}
                />
                <Typography
                    variant={'h3'}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        // fontWeight: 'bold',
                        color: theme.palette.primary.main,
                    }}>
                    {text}
                </Typography>
            </Box>
        </Link>
    );
}

export default Thumbnail;
