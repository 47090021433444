import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import researchData from '../data/researchData';
import Research from './Research';

function ResearchPage() {
    return (
        <Container maxWidth='lg'>
            <Box
                sx={{
                    py: 2,
                    mb: 2,
                    borderBottom: 'solid',
                    borderColor: 'rgb(215, 211, 211)',
                    borderBottomWidth: '1px',
                }}>
                <Typography variant={'h3'} textAlign='start'>
                    Research Projects
                </Typography>
            </Box>
            {researchData.map((p, i) => (
                <Research key={i} research={p}/>
            ))}
        </Container>
    );
}

export default ResearchPage;
