import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Thumbnail from "./Thumbnail";
import peopleImage2 from "../assets/peopleImage2.jpeg";
import publicationImage2 from "../assets/homeImage.jpeg";
import ContactImage3 from "../assets/contactImage3.jpeg";
import Typography from "@mui/material/Typography";
import News from "./News";
import Container from "@mui/material/Container";
import {useTheme} from "@mui/material/styles";

function NewsSection() {
    const theme = useTheme();
    return (
        <Container maxWidth='xl' sx={{textAlign: 'start'}}>
                <Box sx={{mb: 4}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Thumbnail image={peopleImage2} text={'People'}/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Thumbnail image={publicationImage2} text={'Publications'}/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Thumbnail image={ContactImage3} text={'Contact'}/>
                        </Grid>
                    </Grid>
                </Box>
                <Typography variant={'h4'}>Recent News</Typography>

            <News>
                September 2024: We are looking for stellar post-doctoral researchers to join our group. Please email{' '}
                <a
                href='mailto:nandita@cs.toronto.edu'
                style={{textDecoration: 'none', color: theme.palette.info.main}}>nandita@cs.toronto.edu</a>{' '}
                 if you are interested.
            </News>

            <News>
                September 2024: We welcome our new graduate students for 2024: Egil and Mohammadreza!
            </News>

            <News>August 2024: Sankeerth's work ACE: Efficient GPU Kernel Concurrency for Input-Dependent Irregular Computational Graphs has been accepted at{' '}
                <a
                    href='https://pact2024.github.io/'
                    style={{textDecoration: 'none', color: theme.palette.info.main}}>
                    PACT 2024
                </a>!
            </News>

            <News>May 2024: Sankeerth presented Distributed Training of Neural Radiance Fields: A Performance Characterization at{' '}
                <a
                    href='https://www.ispass.org/ispass2024/'
                    style={{textDecoration: 'none', color: theme.palette.info.main}}>
                    ISPASS 2024
                </a>!
            </News>

            <News>
                May 2024:{' '}
                <a
                    href='https://arxiv.org/abs/2404.12512'
                    style={{textDecoration: 'none', color: theme.palette.info.main}}>
                    Proteus: Preserving Model Confidentiality during Graph Optimizations
                </a>
                {' '}by Yubo Gao, Maryam Haghifam, Christina Giannoula, Renbo Tu, Gennady Pekhimenko, Nandita Vijaykumar
                has been accepted at{' '}
                <a
                    href='https://mlsys.org/Conferences/2024'
                    style={{textDecoration: 'none', color: theme.palette.info.main}}>
                    MLSys 2024
                </a>
                . The paper will be presented at{' '}
                <a
                    href='https://mlsys.org/Conferences/2024'
                    style={{textDecoration: 'none', color: theme.palette.info.main}}>
                    MLSys 2024
                </a>
                {' '}(May 2024).
            </News>

            </Container>
    );
}

export default NewsSection;