import React, {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import embarc from '../assets/embarc_b.png'
import {useTheme} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import {NavLink, useNavigate} from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


const tabs = ['Home', 'People', 'Publications', 'Contact'];

function Navbar() {
    const theme = useTheme();
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (text) => {
        setAnchorEl(null);
        navigate(text === 'home' ? '/' : text)
    };

    return (
        <AppBar position='static' elevation={0}>
            <Toolbar disableGutters sx={{mx: 5}}>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                    <a href='/'>
                        <img src={embarc} style={{width: '10rem'}} alt={'embarc-logo'}/>
                    </a>
                </Box>
                <Typography variant='h5' sx={{color: 'black', ml: 1}}>
                    Research Group
                </Typography>
                <Box sx={{ml: 'auto', display: {xs: 'none', md: 'flex'}}}>
                    {tabs.map((text, i) => (
                        <NavLink
                            key={text}
                            style={{textDecoration: 'none'}}
                            to={text === 'Home' ? '/' : text.toLowerCase()}>
                            <Typography
                                variant='h6'
                                sx={{
                                    textDecoration: 'none',
                                    color: theme.palette.info.main,
                                    mr: i === tabs.length - 1 ? 0 : 3,
                                }}>
                                {text}
                            </Typography>
                        </NavLink>
                    ))}
                </Box>
                <Box sx={{ml: 'auto', display: {xs: 'flex', md: 'none'}}}>
                    <IconButton onClick={handleClick}>
                        <MenuIcon/>
                    </IconButton>
                    <Menu
                        id='basic-menu'
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}>
                        {
                            tabs.map((text, i) => (
                                <MenuItem key={i} onClick={() => handleClose(text.toLowerCase())}>
                                    {text}
                                </MenuItem>
                            ))
                        }
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar>
    );
}

export default Navbar;
