import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useTheme} from '@mui/material/styles';
import EmailIcon from '@mui/icons-material/Email';
import contacts from '../assets/UofTHomeBackground2.jpeg';


function Contact() {
    const theme = useTheme();
    return (
        <Container maxWidth='lg'>
            <Box
                sx={{
                    py: 2,
                    mb: 2,
                }}>
                <Typography variant={'h3'} textAlign='start'>
                    Contact
                </Typography>
                <img
                    src={contacts}
                    style={{
                        width: '100%',
                        height: '70%',
                        objectFit: 'cover',
                        objectPosition: 'top',
                        paddingTop: '5vh'
                    }}
                    alt={"contact"}
                />
                <Box>
                    <Typography variant={'h6'} textAlign={'start'} sx={{paddingTop: '10px'}}>
                        We are always looking for excellent undergraduate and graduate students!<br/>
                        If you are a graduate student, please apply directly to the Computer Science graduate program at
                        University of Toronto. <br/>
                        Please email us directly if you are an undergraduate student interested in doing research with
                        us.
                    </Typography>
                    <a href='mailto:nandita@cs.toronto.edu' style={{textDecoration: 'none'}}>
                        <Typography variant={'h6'} textAlign={'start'} sx={{
                            color: theme.palette.info.main,
                            display: 'flex',
                            alignItems: 'center',
                            paddingTop: '25px'
                        }}>
                            <EmailIcon sx={{paddingRight: '5px'}}/> Email: nandita@cs.toronto.edu
                        </Typography>
                    </a>
                    <Typography variant={'h6'} textAlign={'start'} sx={{paddingTop: '10px'}}>
                        Department of Computer Science<br/>
                        University of Toronto <br/>
                        40 St George St<br/>
                        Toronto<br/>
                        ON M5S 2E4<br/>
                    </Typography>
                </Box>
            </Box>
        </Container>
    );
}

export default Contact;
