const peopleData = {
    faculty: [
        {
            name: 'Nandita Vijaykumar',
            title: 'Assistant Professor',
            imgName: 'nandita_v.jpeg',
            link: 'https://www.cs.toronto.edu/~nandita/',
        },
    ],
    grad: [
        {
            name: 'Ruofan Liang',
            title: 'Ph.D. Student',
            imgName: 'ruofan_l.png',
            link: 'https://nexuslrf.github.io/',
        },
        {
            name: 'Sankeerth Durvasula',
            title: 'Ph.D. Student',
            imgName: 'sankeerth_d.jpeg',
            link: 'https://www.cs.toronto.edu/~sankeerth/',
        },
        {
            name: 'Gavin Guan',
            title: 'Ph.D. Student',
            imgName: 'gavin_g.jpg',
            link: 'https://gavinguan95.github.io/',
        },
        {
            name: 'Pawan Kumar Sanjaya',
            title: 'Ph.D. Student',
            imgName: 'pawan_s.jpg',
            link: 'https://pawks.github.io/',
        },
        {
            name: 'Adrian Zhao',
            title: 'Ph.D. Student',
            imgName: 'adrian_z.jpg',
            link: 'https://www.cs.toronto.edu/~adrianz/',
        },
        {
            name: 'Andreas Burger',
            title: 'Ph.D. Student',
            imgName: 'andreas_b.jpg',
            link: 'https://www.andreas-burger.com/',
        },
        {
            name: 'Egil Karlsen',
            title: 'Ph.D. Student',
            imgName: 'egil_k.jpg',
            link: 'https://www.linkedin.com/in/egil-karlsen/'
        },
        {
            name: 'Mohammadreza Mofayezi',
            title: 'Ph.D. Student',
            imgName: 'mohammadreza_m.jpg',
            link: 'mailto:mofayezi@cs.toronto.edu'
        },
        {
            name: 'Filip Grigorov',
            title: 'MSc Student',
            imgName: 'filip_g.jpg',
            link: 'https://filipgrigorov-portfolio.github.io/',
        },
        {
            name: 'Fan Chen',
            title: 'MScAC Student',
            imgName: 'fan_c.jpg',
            link: 'https://www.linkedin.com/in/fan-chen-680ba9158/',
        },
        {
            name: 'Yuchen Chen',
            title: 'MScAC Student',
            imgName: 'yuchen_c.png',
            link: 'https://www.linkedin.com/in/yuchen-chen-yc',
        },
        {
            name: 'Sumant Bagri',
            title: 'MScAC Student',
            imgName: 'sumant_b.jpg',
            link: 'https://www.linkedin.com/in/sumant-bagri-6aa973100',
        },
        {
            name: 'Yuxuan Zhang',
            title: 'MScAC Student',
            imgName: 'Yuxuan.jpeg',
            link: 'https://www.cs.toronto.edu/~yuxuan/',
        },
        {
            name: 'Preet Vimalkumar Viradiya',
            title: 'MScAC Student',
            imgName: 'preet_v.jpg',
            link: 'https://www.linkedin.com/in/preetviradiya/',
        }
    ],
    interns: [
        {
            name: 'Daniel Kwan',
            title: 'Undergraduate Intern',
            imgName: 'daniel_k.png',
            link: 'https://www.linkedin.com/in/danielwtkwan',
        },
        {
            name: 'Alec Dewulf',
            title: 'Undergraduate Intern',
            imgName: 'alec_d.png',
            link: 'https://www.alecdewulf.com/',
        },
        {
            name: 'Callum Sharrock',
            title: 'Intern',
            imgName: 'callum_s.jpg',
            link: 'https://www.linkedin.com/in/callum-sharrock/'
        },
        {
            name: 'Jean-Sebastien Dandurand',
            title: 'Undergraduate Intern',
            imgName: 'jean_d.jpg',
            link: 'https://www.linkedin.com/in/jean-sebastien-dandurand-6b822913b/',
        },
        {
            name: 'Sannat Bhasin',
            title: 'Undergraduate Intern',
            imgName: 'sannat_b.jpeg',
            link: 'https://www.linkedin.com/in/sannatbhasin/',
        },
        {
            name: 'Chunlin Li',
            title: 'Intern',
            imgName: 'chunlin_l.jpg',
            link: 'https://www.linkedin.com/in/chunlinli11235/',
        },
        {
            name: 'Dorsa Molaverdikhani',
            title: 'Intern',
            imgName: 'dorsa_m.jpg',
            link: 'https://www.linkedin.com/in/dorsa-molaverdikhani/',
        },
        {
            name: 'Gabriel Wu',
            title: 'Intern',
            imgName: 'gabriel_w.jpg',
            link: 'https://www.linkedin.com/in/gabriel-wu-2b997b215/',
        },
        {
            name: 'Jeanine Ohene-Agyei',
            title: 'Intern',
            imgName: 'jeanine_o.jpg',
            link: 'https://www.linkedin.com/in/jeanine-ohene-agyei-8a7bb0239/',
        },
        {
            name: 'Solemann Mateen',
            title: 'Intern',
            imgName: 'solemann_m.jpg',
            link: 'https://www.linkedin.com/in/solemann-mateen/',
        },
        {
            name: 'Sharanshangar Muhunthan',
            title: 'Undergraduate Intern',
            imgName: 'sharanshangar_m.jpeg',
            link: 'https://shangar.ddns.net/',
        },
        {
            name: 'Nikhil Sreekumar',
            title: 'Intern',
            imgName: 'nikhil_s.jpg',
            link: 'https://www.linkedin.com/in/nikhil-sreekumar-a948481a6/',
        },
        {
            name: 'Peng Du',
            title: 'Intern',
            imgName: 'peng_d.jpg',
            link: 'https://www.linkedin.com/in/vim-du/',
        },
        {
            name: 'Qingyuan Wu',
            title: 'Intern',
            imgName: 'qingyuan_w.jpg',
            link: 'https://www.linkedin.com/in/qingyuan-wu/',
        },
        {
            name: 'Rishit Dagli',
            title: 'Intern',
            imgName: 'rishit_d.jpg',
            link: 'https://www.linkedin.com/in/rishit-dagli/',
        },
        {
            name: 'Olivia Yan',
            title: 'Intern',
            imgName: 'olivia_y.jpg',
            link: 'https://www.linkedin.com/in/olivia-yan-a881ab263/',
        },
        {
            name: 'Yifei Zhang',
            title: 'Intern',
            imgName: 'placeholder.png',
            link: 'mailto:yifeiizhang@cs.toronto.edu',
        },
        {
            name: 'Dunyang Ni',
            title: 'Intern',
            imgName: 'placeholder.png',
            link: 'mailto:dunyang.ni@mail.utoronto.ca',
        },
        {
            name: 'Chenjia Hu',
            title: 'Intern',
            imgName: 'placeholder.png',
            link: 'mailto:chenjia.hu@mail.utoronto.ca',
        },
        {
            name: 'Harshit Gupta',
            title: 'Undergraduate Intern',
            imgName: 'placeholder.png',
            link: 'https://www.linkedin.com/in/harshit-gupta-0947571b2/',
        },
    ],
    others: [
        {
            name: 'Samarth Mathur',
            title: 'Research Intern',
            imgName: 'samarth_m.jpg',
            link: 'https://www.linkedin.com/in/samarth-mathur-a8813b174',
        },
        {
            name: 'Christina Giannoula',
            title: 'Postdoc at University of Toronto',
            imgName: 'Christina.jpg',
            link: 'https://cgiannoula.github.io/',
        },
        {
            name: 'Konstantinos Kanellopolous',
            title: 'Ph.D. Student at ETH Zürich',
            imgName: 'konstantinos_k.jpeg',
            link: 'https://konkanello.github.io/#',
        },
        {
            name: 'Nika Mansouri Ghiasi',
            title: 'Ph.D. Student at ETH Zürich',
            imgName: 'nika_g.jpeg',
            link: 'https://www.linkedin.com/in/nika-mansouri-ghiasi-4b521ba2/?originalSubdomain=ir',
        },
        {
            name: 'Hyunsoo Kim',
            title: 'Industrial Researcher @ LG',
            imgName: 'placeholder.png',
            link: 'https://www.linkedin.com/in/hyunsoo-kim-27030a17/'
        }
    ],
    grad_alumni: [
        {
            name: 'Jimmy Lin',
            title: 'Graduated MSc',
            link: '',
        },
        {
            name: 'Maryam Haghifam',
            title: 'Graduated MSc',
            imgName: 'maryam_h.jpg',
            link: 'https://www.linkedin.com/in/maryamhaghifam-92b64a78/',
        },
        {
            name: 'Aida Ebrahimi',
            title: 'Graduated MSc',
            link: '',
        }
    ],
    intern_alumni: [
        {
            name: 'Hongyi Sun',
            title: 'Intern',
            link: '',
        },
        {
            name: 'Haoda Li',
            title: 'Intern',
            link: '',
        },
        {
            name: 'Jenny Xu',
            title: 'Intern',
            link: '',
        },
        {
            name: 'Jonathon Tang',
            title: 'Intern',
            link: '',
        },
        {
            name: 'Kailong Huang',
            title: 'Intern',
            link: '',
        },
        {
            name: 'Yingke Wang',
            title: 'Intern',
            link: '',
        },
        {
            name: 'Yuchong Zhang',
            title: 'Intern',
            link: '',
        },
        {
            name: 'Zhonghan Chen',
            title: 'Intern',
            imgName: 'zhonghan_c.jpg',
            link: '',
        },
        {
            name: 'Shaoxuan Zheng',
            title: 'Intern',
            imgName: 'shaoxuan_z.png',
            link: '',
        },
        {
            name: 'Huiting Chen',
            title: 'Intern',
            imgName: 'huiting_c.jpg',
            link: '',
        },
        {
            name: 'Jiahao Zhang',
            title: 'Intern',
            imgName: 'jiahao_z.jpg',
            link: 'https://www.linkedin.com/in/jiahao-zhang-4988ba230/',
        },
        {
            name: 'Raymond Kigaru',
            title: 'Intern',
            imgName: 'raymond_k.png',
            link: '',
        },
        {
            name: 'Kevin Deng',
            title: 'Intern',
            imgName: 'Kevin-Deng.jpeg',
            link: 'https://www.linkedin.com/in/haoze-kevin-deng-95969b205/',
        },
        {
            name: 'Pooria Poorsavi Tehrani',
            title: 'Intern',
            imgName: 'pooria_t.jpg',
            link: 'https://ppt-productions.com/',
        },
        {
            name: 'Sung Ha',
            title: 'Intern',
            imgName: '',
            link: '',
        },
        {
            name: 'Ankur Verma',
            title: 'Intern',
            imgName: '',
            link: '',
        },
        {
            name: 'Dev Agarwal',
            title: 'Intern',
            imgName: '',
            link: '',
        },
        {
            name: 'Shorya Kumar',
            title: 'Intern',
            imgName: '',
            link: '',
        },
        {
            name: 'Edward Li',
            title: 'Undergraduate Intern',
            imgName: 'Edward-Li.jpg',
            link: 'https://ppt-productions.com/',
        },
        {
            name: 'Andrew D\'Amario',
            title: 'Undergraduate Intern',
            imgName: 'andrew_da.png',
            link: 'https://github.com/andrewaclear',
        },
        {
            name: 'Louis Zhang',
            title: 'Undergraduate Intern',
            imgName: 'louis_z.jpg',
            link: 'https://github.com/AX-I',
        },
        {
            name: 'Kermit Zhao',
            title: 'MScAC Student',
            imgName: 'kermit_z.png',
            link: 'https://www.linkedin.com/in/ke-kermit-zhao-153848184',
        },
        {
            name: 'Zichuan Guan',
            title: 'MScAC Student',
            imgName: 'zichuan_g.png',
            link: 'https://www.linkedin.com/in/zichuan-guan/',
        },
        {
            name: 'Benjamin Correia',
            title: 'MScAC Student',
            imgName: 'placeholder.png',
            link: 'mailto:ben@cs.toronto.edu',
        },
        {
            name: 'Ariel Chouminov',
            title: 'Undergraduate Intern',
            imgName: 'ariel_c.jpeg',
            link: 'https://www.linkedin.com/in/arielchouminov/',
        },
        {
            name: 'Devan Srinivasan',
            title: 'Undergraduate Intern',
            imgName: 'devan_s.png',
            link: 'https://mrmackamoo.ca/',
        },
        {
            name: 'Hanrui Fan',
            title: 'Undergraduate Intern',
            imgName: 'hanrui_f.jpg',
            link: 'https://www.linkedin.com/in/hanrui-fan-jerry',
        },
        {
            name: 'Kaiwen Zheng',
            title: 'Undergraduate Intern',
            imgName: 'kaiwen_z.jpeg',
            link: 'https://www.linkedin.com/in/kaiwen-zheng-289182202/',
        },
        {
            name: 'Mohannad Shehata',
            title: 'Undergraduate Intern',
            imgName: 'mohannad_s.jpg',
            link: 'https://www.linkedin.com/in/mohannad-shehata',
        },
        {
            name: 'Sam Prokopchuk',
            title: 'Undergraduate Intern',
            imgName: 'sam_p.jpeg',
            link: 'https://www.linkedin.com/in/sam-prokopchuk-1908b21a0/',
        },
        {
            name: 'Richard Huang',
            title: 'Undergraduate Intern',
            imgName: 'xiaorui_h.jpeg',
            link: 'https://www.linkedin.com/in/xiaorui-richard-huang/',
        },
        {
            name: 'Mariia Shpir',
            title: 'MITACS Intern',
            imgName: '',
            link: '',
        },
        {
            name: 'Sumit Laddha',
            title: 'MITACS Intern',
            imgName: '',
            link: '',
        }
    ],
    assistant: [
        {
            name: 'Siqi Liu',
            title: 'Lab Assistant',
            imgName: 'siqi_l.jpeg',
            link: 'http://portfoliu.net',
        },
        {
            name: 'Rachel Kwan',
            title: 'Lab Assistant',
            imgName: 'rachel_k.jpg',
            link: 'https://www.linkedin.com/in/rachelkwan/',
        },
    ],
};

export {peopleData};
