import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'

function News({children}) {
    return (
        <Box
            sx={{
                my: 3,
                mx: 2,
                pb: 3,
                borderBottom: 'solid',
                borderColor: 'rgb(215, 211, 211)',
                borderBottomWidth: '1px',
            }}>
            <Typography variant='h6'>
                {children}
            </Typography>
        </Box>
    );
}

export default News;
