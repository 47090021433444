import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import embarcImage from '../assets/embarc.png';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import NewsSection from "./NewsSection";

function Home() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <>
            <Box
                id={'home-background'}
                sx={{
                    filter: 'brightness(1)',
                    minHeight: 'fit-content',
                    color: 'white',
                    backgroundSize: 'cover',
                    backgroundPosition: 'left',
                    alignItems: 'center',
                    display: 'flex',
                    mb: 8,
                    py: 5,
                }}>
                <Grid container sx={{display: 'flex', alignItems: 'center'}}>
                    <Grid item xs={12} md={6}>
                        <Typography variant='h5' textAlign={'justify'} sx={{mx: 5, my: 5}}>
                            Welcome to the embARC research group at the University of Toronto! embARC is led by{' '}
                            <a
                                href='https://www.cs.toronto.edu/~nandita/'
                                style={{textDecoration: 'none', color: '#73f3ff', fontWeight: 'bold'}}>
                                Professor Nandita Vijaykumar
                            </a>{' '}
                            and is a part of the{' '}
                            <a
                                href='http://csng.cs.toronto.edu/index.php'
                                style={{textDecoration: 'none', color: '#73f3ff', fontWeight: 'bold'}}>
                                Computer Systems Group
                            </a>{' '}
                            in the{' '}
                            <a
                                href='https://web.cs.toronto.edu/'
                                style={{textDecoration: 'none', color: '#73f3ff', fontWeight: 'bold'}}>
                                Department of Computer Science
                            </a>{' '}
                            at the{' '}
                            <a
                                href='https://www.utoronto.ca/'
                                style={{textDecoration: 'none', color: '#73f3ff', fontWeight: 'bold'}}>
                                University of Toronto
                            </a>
                            . We conduct research at the intersection of computer architecture/systems and machine
                            learning, robotics, and computer vision. We are also affiliated with the{' '}
                            <a
                                href='https://vectorinstitute.ai/'
                                style={{textDecoration: 'none', color: '#73f3ff', fontWeight: 'bold'}}>
                                Vector Institute
                            </a>{' '}
                            and the{' '}
                            <a
                                href='https://robotics.utoronto.ca/'
                                style={{textDecoration: 'none', color: '#73f3ff', fontWeight: 'bold'}}>
                                Robotics Institute
                            </a>.
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{display: 'flex', justifyContent: matches ? 'center' : 'flex-end'}}>
                        <Stack sx={{display: 'flex', alignItems: 'center'}}>
                            <Box
                                sx={{
                                    width: '60%',
                                    height: 'auto',
                                    maxWidth: '40rem',
                                    overflow: 'hidden',
                                }}>
                                <img
                                    src={embarcImage}
                                    alt={'uoft logo'}
                                    style={{
                                        transform: 'scale(3)',
                                        maxWidth: '50%',
                                        maxHeight: '40rem',
                                        objectFit: 'cover',
                                    }}
                                />
                            </Box>
                            <Typography variant='h4' sx={{mb: 3}}>
                                Research Group
                            </Typography>
                            <Box sx={{textAlign: 'center'}}>
                                <Typography variant={'h5'} sx={{fontFamily: 'Bembo', lineHeight: '100%'}}>
                                    UNIVERSITY OF
                                </Typography>
                                <Typography variant={'h3'} sx={{fontFamily: 'Bembo', lineHeight: '100%', mb: 1}}>
                                    TORONTO
                                </Typography>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            <NewsSection/>
        </>
    );
}

export default Home;
